import { LEFT_NAVIGATION_PAGE_URL } from "./navigation.models";
import { PAGE_CONFIG } from "./navigation.models";
var USER_PRIVILEGE = PAGE_CONFIG.USER_PRIVILEGE;
export var API_PARAM_NULL_VALUE = " ";
export var TASKSTATUS = {
    1: "Open",
    2: "Upcoming",
    3: "Done",
};
export var EXCEPTION_STATUS_FILTERS = [
    { key: 1, text: "All", value: 1 },
    { key: 2, text: "Active", value: 2 },
    { key: 3, text: "Resolved", value: 3 },
];
// Used this model to check the previous request sent in case of confirmation popup.
export var RequestTypes = {
    UpdateTaskDetails: "UpdateTaskDetails",
    UpdateTaskAssigneeStatus: "UpdateTaskAssigneeStatus",
    BreakDependency: "BreakDependency",
    UpdateCommunicationLog: "UpdateCommunicationLog",
};
export var ItemsPerPage = [
    { key: "1", text: "10", value: "10" },
    { key: "2", text: "25", value: "25" },
    { key: "3", text: "50", value: "50" },
    { key: "4", text: "75", value: "75" },
    { key: "5", text: "100", value: "100" },
];
export var AppGridIds = {
    MemberGrid: 1,
    OrganizationGrid: 2,
    CommitteeGrid: 3,
    CommitteeRosterGrid: 4,
    TaskGrid: 5,
    MembershipException: 6,
    FeeGroupGrid: 7,
};
export var minDateForAuditLogs = "01/01/1700";
export var minDateForInputDateFields = "01/01/1900";
export var endDayTime = " 23:59:59";
export var AdvanceSearchQueryFields = {
    IsAdvanceSearch: "isAdvanceSearch",
    GridId: "gridid",
    CommitteeDesignation: "committeedesignation",
    CommitteeTitle: "committeetitle",
    Status: "status",
    Classification: "classification",
    CommitteeType: "committeetype",
    CommitteeLevel: "committeelevel",
    FeeGroupNumber: "feeGroupNumber",
    FeeGroupTitle: "feeGroupTitle",
    PaidStatus: "paidStatus",
    AutoRenewal: "autoRenewal",
    Country: "country",
    ContactName: "contactName",
    AssociatedMemberAccountNo: "associatedMemberAccountNo",
    AssociatedMemberFirstName: "associatedMemberFirstName",
    AssociatedMemberLastName: "associatedMemberLastName",
};
export var ReasonForUpdateMaxLength = "200";
export var CountryCodes = {
    UNITEDSTATES: { Code: "US", Name: "United States" },
    CANADA: { Code: "CA", Name: "Canada" },
};
export var McsStatusMasterId = {
    ActiveCommittee: 1,
    InactiveCommmittee: 3,
    ActiveCommitteeMember: 11,
    InactiveCommitteeMember: 12,
    ActiveMember: 17,
    HistoricalMember: 18,
    InactiveMember: 19,
};
// Add the gri columns here as needed. Do not hard code in any file
export var GridColumns = {
    Email: "Email",
    Action: "Action",
    MemberDetail: {
        Email: "MemberDetail_Email",
        MemberTypeId: "MemberDetail_MemberTypeId",
        MemberId: "MemberDetail_MemberId",
        AccountNumber: "MemberDetail_AccountNumber",
        FacilityName: "MemberDetail_FacilityName",
        OrgAccountNumber: "MemberDetail_OrgAccountNumber",
        RepMemberName: "MemberDetail_RepMemberName",
        RepAccountNumber: "MemberDetail_RepAccountNumber",
        PhoneNumber: "MemberDetail_PhoneNumber",
        OfficerTitleName: "MemberDetail_OfficerTitleName",
        Classification: "MemberDetail_Classification",
        NoVoteReason: "MemberDetail_NoVoteReason",
        Vote: "MemberDetail_Vote",
        JoinedDate: "MemberDetail_JoinedDate",
        ClassificationDate: "MemberDetail_ClassificationDate",
        VoteDate: "MemberDetail_VoteDate",
        MemberCommitteeStatus: "MemberDetail_MemberCommitteeStatus",
        McsStatusMasterId: "MemberDetail_McsStatusMasterId",
        MemberCommitteeStatusId: "MemberDetail_MemberCommitteeStatusId",
    },
    CommitteeDetail: {
        CommitteeDesignation: "CommitteeDetail_CommitteeDesignation",
        CommitteeId: "CommitteeDetail_CommitteeId",
        CommitteeClassification: "CommitteeDetail_CommitteeClassification",
        CommitteeTitle: "CommitteeDetail_CommitteeTitle",
        Status: "CommitteeDetail_Status",
        InactiveReason: "CommitteeDetail_InactiveReason",
        InactiveDate: "CommitteeDetail_InactiveDate",
        MergedCommittee: "CommitteeDetail_MergedCommittee",
        Chairman: "CommitteeDetail_Chairman",
        LevelId: "CommitteeDetail_LevelId",
        SubCommitteeChair: "CommitteeDetail_SubCommitteeChair",
    },
    ExceptionDetails: {
        AccountNumber: "AccountNumber",
        MemberName: "MemberName",
        OrderType: "OrderType",
        Source: "Source",
        ExceptionReason: "ExceptionReason",
        MessageDescription: "MessageDescription",
        EncounteredOn: "EncounteredOn",
        Status: "Status",
        ModifiedBy: "ModifiedBy",
    },
};
// TODO : get domain prefix to set cookies doamin wise.
export var getDoaminPrefix = function () {
    var hostname = window.location.hostname;
    var prefixName = "";
    if (hostname) {
        var envPrefix = hostname.indexOf("-") > -1 ? hostname.split("-") : "";
        if (envPrefix && envPrefix.length > 0) {
            prefixName = "".concat(envPrefix[0].toUpperCase());
            if (prefixName == "QA") {
                prefixName = "TEST";
            }
            if (["DEV", "TEST", "STAGE"].includes(prefixName)) {
                prefixName = prefixName + "-";
            }
        }
    }
    return prefixName;
};
export var cookieKeysEnum = {
    APP_SESSIONID: "".concat(getDoaminPrefix(), "MCS-AZURE-APP-SESSION-ID"),
    accessToken: "".concat(getDoaminPrefix(), "MCS-AZURE-ACESS-TOKEN"),
};
export var OfficerTitleEnum = {
    StaffManager: "Staff Manager",
    AdministrativeAssistant: "Administrative Assistant",
    Chair: "Chair",
    SubChair: "Sub Committee Chair",
};
export var ReportTypeEnum = [
    { label: "Member Facing (PDF)", value: 1, key: 1 },
    { label: "Working Schedule (PDF)", value: 2, key: 2 },
];
export var ReportTypeEnumLineSchedule = [
    { text: "Select", value: "", key: "" },
    { text: "Member Facing (Excel)", value: 1, key: 1 },
    { text: "Member Facing (PDF)", value: 2, key: 2 },
    { text: "Working Schedule (PDF)", value: 3, key: 3 },
];
export var ReportTypeEnumRegistrationFeesReport = [
    { text: "Select", value: "", key: "" },
    { text: "Detailed Meeting Report", value: 1, key: 1 },
    { text: "Detailed Symposia Report", value: 3, key: 3 },
    { text: "Committee Attendance Summary", value: 2, key: 2 },
    { text: "D02 Accompanying Companion Report", value: 4, key: 4 },
    { text: "Door Sign Report", value: 5, key: 5 },
];
export var meetingSymposiaType = { 1: "Meeting", 3: "Symposia" };
export var TemaplateTypeEnum = {
    blockShedule: "block-shedule",
    lineSheduleReport: "line-shedule-report",
};
export var S3_URL_TYPE = {
    RENEWAL_TASK_UPLOAD: 1,
    RENEWAL_TASK_DOWNLOAD: 2,
    BALLOT_ADMIN_FILE_UPLOAD: 3,
    BALLOT_ADMIN_FILE_DOWNLOAD: 4,
};
export var disableViewSubModulePrivilegeId = [129, 130, 131];
export var privilegeName = [USER_PRIVILEGE.VIEW];
export var D02_Committee = "D02";
export var COMPANY_TYPE = {
    COMPANY: 1,
    FACILITY: 2,
};
export var ReportTypeEnumAccountingReport = [
    { text: "Select", value: "", key: "" },
    { text: "Accounting Report", value: "1", key: "1" },
    { text: "Cost Center Report", value: "2", key: "2" },
];
export var eventListParamsEnum = {
    Event_Status: "?eventstatus=",
    Event_Type: "&eventtype=",
    Meeting_Symposia: "&meetingsymposiatype=",
    Committee_Designation: "&committeedesignation=",
    From_Date: "&fromdate=",
    To_Data: "&todate=",
};
export var event_Type = {
    Independent_Meeting: "IndependentMeeting",
    Committee_Week: "CommitteeWeek",
    Icam: "ICAM",
};
export var meetingSymposiaEnum = {
    Meeting: "Meeting",
    Symposia: "Symposia",
};
export var event_Status = "Cancelled,Live,On-Site,Pre-Event,Sold Out";
export var independentMeetingAndCommitteeWeek = "".concat(event_Type.Independent_Meeting, ",").concat(event_Type.Committee_Week);
export var independentMeetingCommitteeWeekAndIcam = "".concat(event_Type.Independent_Meeting, ",").concat(event_Type.Committee_Week, ",").concat(event_Type.Icam);
export var meetingAndSymposia = "".concat(meetingSymposiaEnum.Meeting, ",").concat(meetingSymposiaEnum.Symposia);
export var ALLDOCUMENTSHEADERINFO = [
    {
        title: "Document Name",
        hasSorting: true,
        className: "",
        sortKey: "Files",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Committee",
        hasSorting: true,
        className: "",
        sortKey: "Committee",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Status",
        hasSorting: true,
        className: "",
        sortKey: "Status",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Modified Date",
        hasSorting: true,
        className: "",
        sortKey: "ModifiedDate",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
];
export var fileTypeSVGFormat = [
    {
        fileEndWith: "docx",
        path: "M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm220.1-208c-5.7 0-10.6 4-11.7 9.5-20.6 97.7-20.4 95.4-21 103.5-.2-1.2-.4-2.6-.7-4.3-.8-5.1.3.2-23.6-99.5-1.3-5.4-6.1-9.2-11.7-9.2h-13.3c-5.5 0-10.3 3.8-11.7 9.1-24.4 99-24 96.2-24.8 103.7-.1-1.1-.2-2.5-.5-4.2-.7-5.2-14.1-73.3-19.1-99-1.1-5.6-6-9.7-11.8-9.7h-16.8c-7.8 0-13.5 7.3-11.7 14.8 8 32.6 26.7 109.5 33.2 136 1.3 5.4 6.1 9.1 11.7 9.1h25.2c5.5 0 10.3-3.7 11.6-9.1l17.9-71.4c1.5-6.2 2.5-12 3-17.3l2.9 17.3c.1.4 12.6 50.5 17.9 71.4 1.3 5.3 6.1 9.1 11.6 9.1h24.7c5.5 0 10.3-3.7 11.6-9.1 20.8-81.9 30.2-119 34.5-136 1.9-7.6-3.8-14.9-11.6-14.9h-15.8z",
    },
    {
        fileEndWith: "doc",
        path: "M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm220.1-208c-5.7 0-10.6 4-11.7 9.5-20.6 97.7-20.4 95.4-21 103.5-.2-1.2-.4-2.6-.7-4.3-.8-5.1.3.2-23.6-99.5-1.3-5.4-6.1-9.2-11.7-9.2h-13.3c-5.5 0-10.3 3.8-11.7 9.1-24.4 99-24 96.2-24.8 103.7-.1-1.1-.2-2.5-.5-4.2-.7-5.2-14.1-73.3-19.1-99-1.1-5.6-6-9.7-11.8-9.7h-16.8c-7.8 0-13.5 7.3-11.7 14.8 8 32.6 26.7 109.5 33.2 136 1.3 5.4 6.1 9.1 11.7 9.1h25.2c5.5 0 10.3-3.7 11.6-9.1l17.9-71.4c1.5-6.2 2.5-12 3-17.3l2.9 17.3c.1.4 12.6 50.5 17.9 71.4 1.3 5.3 6.1 9.1 11.6 9.1h24.7c5.5 0 10.3-3.7 11.6-9.1 20.8-81.9 30.2-119 34.5-136 1.9-7.6-3.8-14.9-11.6-14.9h-15.8z",
    },
    {
        fileEndWith: "pdf",
        path: "M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z",
    },
    {
        fileEndWith: "ppt",
        path: "M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm72-60V236c0-6.6 5.4-12 12-12h69.2c36.7 0 62.8 27 62.8 66.3 0 74.3-68.7 66.5-95.5 66.5V404c0 6.6-5.4 12-12 12H132c-6.6 0-12-5.4-12-12zm48.5-87.4h23c7.9 0 13.9-2.4 18.1-7.2 8.5-9.8 8.4-28.5.1-37.8-4.1-4.6-9.9-7-17.4-7h-23.9v52z",
    },
    {
        fileEndWith: "pptx",
        path: "M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm72-60V236c0-6.6 5.4-12 12-12h69.2c36.7 0 62.8 27 62.8 66.3 0 74.3-68.7 66.5-95.5 66.5V404c0 6.6-5.4 12-12 12H132c-6.6 0-12-5.4-12-12zm48.5-87.4h23c7.9 0 13.9-2.4 18.1-7.2 8.5-9.8 8.4-28.5.1-37.8-4.1-4.6-9.9-7-17.4-7h-23.9v52z",
    },
    {
        fileEndWith: "xls",
        path: "M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3H124c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3H260c9.3 0 15-10 10.4-18L224 320c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z",
    },
    {
        fileEndWith: "xlsx",
        path: "M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3H124c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3H260c9.3 0 15-10 10.4-18L224 320c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z",
    },
    {
        fileEndWith: "xlsm",
        path: "M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3H124c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3H260c9.3 0 15-10 10.4-18L224 320c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z",
    },
    {
        fileEndWith: "zip",
        path: "M128.3 160v32h32v-32zm64-96h-32v32h32zm-64 32v32h32V96zm64 32h-32v32h32zm177.6-30.1L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM256 51.9l76.1 76.1H256zM336 464H48V48h79.7v16h32V48H208v104c0 13.3 10.7 24 24 24h104zM194.2 265.7c-1.1-5.6-6-9.7-11.8-9.7h-22.1v-32h-32v32l-19.7 97.1C102 385.6 126.8 416 160 416c33.1 0 57.9-30.2 51.5-62.6zm-33.9 124.4c-17.9 0-32.4-12.1-32.4-27s14.5-27 32.4-27 32.4 12.1 32.4 27-14.5 27-32.4 27zm32-198.1h-32v32h32z",
    },
    {
        fileEndWith: "rar",
        path: "M128.3 160v32h32v-32zm64-96h-32v32h32zm-64 32v32h32V96zm64 32h-32v32h32zm177.6-30.1L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM256 51.9l76.1 76.1H256zM336 464H48V48h79.7v16h32V48H208v104c0 13.3 10.7 24 24 24h104zM194.2 265.7c-1.1-5.6-6-9.7-11.8-9.7h-22.1v-32h-32v32l-19.7 97.1C102 385.6 126.8 416 160 416c33.1 0 57.9-30.2 51.5-62.6zm-33.9 124.4c-17.9 0-32.4-12.1-32.4-27s14.5-27 32.4-27 32.4 12.1 32.4 27-14.5 27-32.4 27zm32-198.1h-32v32h32z",
    },
];
export var acceptedFileTypes = ["docx", "doc", "pdf", "ppt", "pptx", "xls", "xlsx", "xlsm", "zip", "rar"];
export var getSpecialCharacterValue = function (characterString) {
    var txt = document.createElement("textarea");
    txt.innerHTML = characterString;
    return txt.value;
};
export var convertCharacterEntityToHtml = function (stringValue) {
    var listOfCharacterEntity = ["&lt;", "&gt;", "&amp;", "&quot;", "&apos;", "&cent;", "&pound;", "&yen;", "&euro;", "&copy;", "&reg;"];
    for (var _i = 0, listOfCharacterEntity_1 = listOfCharacterEntity; _i < listOfCharacterEntity_1.length; _i++) {
        var item = listOfCharacterEntity_1[_i];
        if (stringValue.includes(item)) {
            var getSpecialValue = getSpecialCharacterValue(item);
            var newStringValue = stringValue.replace(item, getSpecialValue);
            return newStringValue;
        }
    }
    return stringValue;
};
export var meetingStatusList = [
    { key: "1", value: "1", text: "Active" },
    { key: "2", value: "2", text: "Inactive" },
];
export var documentCategory = { Agenda: 1, Minutes: 2 };
export var navigationForAllDocuments = {
    allDocuments: "/documents/allDocuments",
    agenda: "/documents/agenda",
    minutes: "/documents/minutes",
    documentUpload: "/docupload",
    committees: "/documents/committees",
};
export var AGENDAMINUTESHEADERINFO = [
    {
        title: "Document Name",
        hasSorting: true,
        className: "",
        sortKey: "Files",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Committee",
        hasSorting: true,
        className: "",
        sortKey: "Committee",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Status",
        hasSorting: true,
        className: "",
        sortKey: "Status",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Meeting Date",
        hasSorting: true,
        className: "",
        sortKey: "Dates",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Modified Date",
        hasSorting: true,
        className: "",
        sortKey: "ModifiedDate",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
];
export var COMMITTEESDOCUMENTSHEADERINFO = [
    {
        title: "Seq. No.",
        hasSorting: true,
        className: "",
        sortKey: "SequenceNumber",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Display Name",
        hasSorting: true,
        className: "",
        sortKey: "Files",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Committee",
        hasSorting: true,
        className: "",
        sortKey: "Committee",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Status",
        hasSorting: true,
        className: "",
        sortKey: "Status",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Expiry Date",
        hasSorting: true,
        className: "",
        sortKey: "Dates",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Modified Date",
        hasSorting: true,
        className: "",
        sortKey: "ModifiedDate",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
];
export var categoryIdForDocuments = {
    allDocuments: 0,
    agenda: 1,
    minutes: 2,
    committeesDocuments: 3,
    ballotQueue: 4,
    voteAttachment: 5,
    closingReports: 6,
    ballotItems: 7,
    collaborationAreaDraft: 8,
};
export var getDocumentCategoryId = function () {
    var docCategoryId = 0;
    var urlPathName = window.location.pathname;
    switch (urlPathName) {
        case LEFT_NAVIGATION_PAGE_URL.AGENDAS:
            docCategoryId = 1;
            break;
        case LEFT_NAVIGATION_PAGE_URL.MINUTES:
            docCategoryId = 2;
            break;
        case LEFT_NAVIGATION_PAGE_URL.COMMITTES:
            docCategoryId = 3;
            break;
        case LEFT_NAVIGATION_PAGE_URL.BALLOT_QUEUE:
            docCategoryId = 4;
            break;
        case LEFT_NAVIGATION_PAGE_URL.VOTE_ATTACHMENTS:
            docCategoryId = 5;
            break;
        case LEFT_NAVIGATION_PAGE_URL.CLOSING_REPORTS:
            docCategoryId = 6;
            break;
        case LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS:
        case LEFT_NAVIGATION_PAGE_URL.BALLOT:
            docCategoryId = 7;
            break;
        case LEFT_NAVIGATION_PAGE_URL.COLLABORATION_AREA_DRAFT:
            docCategoryId = 8;
            break;
    }
    return docCategoryId;
};
export var getCategoryNameOfDocuments = function () {
    var categoryNameValue;
    switch (getDocumentCategoryId()) {
        case 1:
            categoryNameValue = "Agendas";
            break;
        case 2:
            categoryNameValue = "Minutes";
            break;
        case 3:
            categoryNameValue = "Committee Documents";
            break;
        case 4:
            categoryNameValue = "Ballot Queue";
            break;
        case 5:
            categoryNameValue = "Vote Attachments";
            break;
        case 6:
            categoryNameValue = "Closing Reports";
            break;
        case 7:
            categoryNameValue = "Ballot Items";
            break;
        case 8:
            categoryNameValue = "Collaboration Area Draft";
            break;
    }
    return categoryNameValue;
};
export var getUrlFromCategoryId = function (documentCategoryId) {
    var documentURL = LEFT_NAVIGATION_PAGE_URL.ALL_DOCUMENTS;
    switch (documentCategoryId) {
        case categoryIdForDocuments.agenda:
            documentURL = LEFT_NAVIGATION_PAGE_URL.AGENDAS;
            break;
        case categoryIdForDocuments.minutes:
            documentURL = LEFT_NAVIGATION_PAGE_URL.MINUTES;
            break;
        case categoryIdForDocuments.committeesDocuments:
            documentURL = LEFT_NAVIGATION_PAGE_URL.COMMITTES;
            break;
        case categoryIdForDocuments.closingReports:
            documentURL = LEFT_NAVIGATION_PAGE_URL.CLOSING_REPORTS;
            break;
        case categoryIdForDocuments.voteAttachment:
            documentURL = LEFT_NAVIGATION_PAGE_URL.VOTE_ATTACHMENTS;
            break;
        case categoryIdForDocuments.collaborationAreaDraft:
            documentURL = LEFT_NAVIGATION_PAGE_URL.COLLABORATION_AREA_DRAFT;
            break;
    }
    return documentURL;
};
export var DocumentCategoryNames;
(function (DocumentCategoryNames) {
    DocumentCategoryNames["Agendas"] = "Agendas";
    DocumentCategoryNames["Minutes"] = "Minutes";
    DocumentCategoryNames["BallotItems"] = "Ballot Items";
})(DocumentCategoryNames || (DocumentCategoryNames = {}));
export var FileExtentionNames;
(function (FileExtentionNames) {
    FileExtentionNames["Agendas"] = "Agendas";
    FileExtentionNames["Minutes"] = "Minutes";
    FileExtentionNames["Docx"] = "docx";
    FileExtentionNames["Doc"] = "doc";
    FileExtentionNames["Pdf"] = "pdf";
    FileExtentionNames["Ppt"] = "ppt";
    FileExtentionNames["Pptx"] = "pptx";
    FileExtentionNames["Xls"] = "xls";
    FileExtentionNames["Xlsx"] = "xlsx";
    FileExtentionNames["Xlsm"] = "xlsm";
    FileExtentionNames["Zip"] = "zip";
})(FileExtentionNames || (FileExtentionNames = {}));
export var getDocumentCategoryInfo = function (id, uploadAsTermsDoc, termDocumentAlreadyExist) {
    var documentCategoryId = Number(id);
    switch (documentCategoryId) {
        case 1:
            return {
                name: "Agendas",
                warning: "2006.text",
                success: "2004",
                danger: "2005.text",
                navigation: LEFT_NAVIGATION_PAGE_URL.AGENDAS,
                successForMultiple: "2040",
                dangerForMultiple: "2042.text",
                failedForUploadFiles: "2044",
            };
        case 2:
            return {
                name: "Minutes",
                warning: "2009.text",
                success: "2007",
                danger: "2008.text",
                navigation: LEFT_NAVIGATION_PAGE_URL.MINUTES,
                successForMultiple: "2041",
                dangerForMultiple: "2043.text",
                failedForUploadFiles: "2045",
            };
        case 3:
            return {
                name: "CommitteeDocuments",
                warning: "2012.text",
                success: "2014",
                danger: "2013.text",
                navigation: LEFT_NAVIGATION_PAGE_URL.COMMITTES,
            };
        case 4:
            return {
                name: "BallotQueue",
                success: "2030",
                navigation: LEFT_NAVIGATION_PAGE_URL.BALLOT_QUEUE,
            };
        case 5:
            return {
                name: "VoteAttachments",
                warning: "2012.text",
                success: "2023",
                danger: "2024.text",
                navigation: LEFT_NAVIGATION_PAGE_URL.VOTE_ATTACHMENTS,
            };
        case 7:
            return {
                name: "BallotItems",
                success: uploadAsTermsDoc ? "2034" : "2033",
                danger: uploadAsTermsDoc && termDocumentAlreadyExist ? "2035.text" : "2032.text",
                navigation: LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS,
            };
        default:
            return;
    }
};
export var BallotQueueStatusFilterList = [
    { key: "0", value: "All", text: "All" },
    { key: "1", value: "MC", text: "MC" },
    { key: "2", value: "SC", text: "SC" },
];
export var CommitteDocStatusFilterList = [
    { key: "111", value: "All", text: "All" },
    { key: "112", value: "Active", text: "Active" },
    { key: "113", value: "Inactive", text: "Inactive" },
];
export var allDocumentsDefaultState = {
    pageSize: 10,
    activePageNumber: 1,
    selectedQuickCommittee: "",
    activeStatusFilter: CommitteDocStatusFilterList[1].value,
    ballotQueueStatusFilterValue: BallotQueueStatusFilterList[0].value,
    sortFilterInfo: {
        sortOrder: "desc",
        sortColumn: ALLDOCUMENTSHEADERINFO[3].sortKey,
        selectedHierarchy: ["2"],
    },
};
export var CLOSINGREPORTSHEADERINFO = [
    {
        title: "Document Name",
        hasSorting: true,
        className: "",
        sortKey: "Files",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Committee",
        hasSorting: true,
        className: "",
        sortKey: "Committee",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Letter Ballot",
        hasSorting: true,
        className: "",
        sortKey: "LetterBallot",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Year",
        hasSorting: true,
        className: "",
        sortKey: "Year",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Modified Date",
        hasSorting: true,
        className: "",
        sortKey: "ModifiedDate",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
];
export var VOTEATTACHMENTHEADERINFO = [
    {
        title: "Document Name",
        hasSorting: true,
        className: "",
        sortKey: "Files",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Committee",
        hasSorting: true,
        className: "",
        sortKey: "Committee",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Status",
        hasSorting: true,
        className: "",
        sortKey: "Status",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Letter Ballot",
        hasSorting: true,
        className: "",
        sortKey: "LetterBallot",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Modified Date",
        hasSorting: true,
        className: "",
        sortKey: "ModifiedDate",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
];
export var BALLOTQUEUEHEADERINFO = [
    {
        title: "Document Name",
        hasSorting: true,
        className: "",
        sortKey: "Files",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Committee",
        hasSorting: true,
        className: "",
        sortKey: "Committee",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Standard",
        hasSorting: true,
        className: "",
        sortKey: "StandardDesignation",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Work Item No.",
        hasSorting: true,
        className: "",
        sortKey: "WorkItemNumber",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "B. Level",
        hasSorting: true,
        className: "",
        sortKey: "BallotLevel",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Modified Date",
        hasSorting: true,
        className: "",
        sortKey: "ModifiedDate",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Created Date",
        hasSorting: false,
        className: "",
        sortKey: "CreatedDate",
        orderBy: 0,
        orderByIcon: 0,
        isShow: false,
    },
];
export var QuickSearchOnDocumentList = {
    ItemNumber: "fieldItemNumber",
    AccountNumber: "fieldAccountNumber",
    CollaborationId: 'fieldCollaborationId',
    BooleanAccountInput: "isAccountInput",
    BooleanItemInput: "isItemNumberInput",
    BooleanCollaborationId: "isCollaborationId"
};
export var ballotLevelList = [
    { key: 0, value: 0, text: "Select" },
    { key: 1, value: 1, text: "MC" },
    { key: 2, value: 2, text: "SC" },
];
export var documentUploadFeilds = ["UploadDocument", "Committee", "DisplayName", "MeetingDate", "LetterBallotNumber", "ItemNumber", "AccountNumber", "StandardDesignation", "WorkItemNumber", "BallotAction", "BallotLevel", "SequenceNumber"];
export var BALLOTITEMSHEADERINFO = [
    {
        title: "Document Name",
        hasSorting: true,
        className: "",
        sortKey: "Files",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Item No.",
        hasSorting: true,
        className: "",
        sortKey: "BallotItemNumber",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Committee",
        hasSorting: true,
        className: "",
        sortKey: "Committee",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Status",
        hasSorting: true,
        className: "",
        sortKey: "BallotStatus",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Letter Ballot",
        hasSorting: true,
        className: "",
        sortKey: "LetterBallot",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Modified Date",
        hasSorting: true,
        className: "",
        sortKey: "ModifiedDate",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
];
export var BallotStatus = {
    Closed: "Closed",
    Prepublish: "Prepublish",
    Publish: "Published",
    PendingPublication: "Pending Publication",
};
export var zipInProgressStatus = 1;
export var isRepublishBallotFolder = 1;
export var maxFileUploadLimit = 50;
export var MCSBallotStatus;
(function (MCSBallotStatus) {
    MCSBallotStatus["New"] = "NEW";
    MCSBallotStatus["Open"] = "OPEN";
})(MCSBallotStatus || (MCSBallotStatus = {}));
export var LitigationHoldListCategoryId = 9;
export var LITIGATIONHOLDLISTHEADERINFO = [
    {
        title: "Committee",
        hasSorting: true,
        className: "",
        sortKey: "MainGroupIdentifier",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Subcommittees/Sections",
        hasSorting: false,
        className: "",
        sortKey: "SubCommittees",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Start Date",
        hasSorting: true,
        className: "",
        sortKey: "StartDate",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "End Date",
        hasSorting: true,
        className: "",
        sortKey: "EndDate",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Document Date Range",
        hasSorting: true,
        className: "",
        sortKey: "DocumentDateRange",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
];
export var SubcommitteesSectionsOptions = [
    {
        id: "AllSubcommitteesSections",
        value: "AllSubcommitteesSections",
        label: "All Subcommittees/Sections",
        siblingComponent: false,
    },
    {
        id: "SpecificSubcommitteesSections",
        value: "SpecificSubcommitteesSections",
        label: "Specific Subcommittees/Sections",
        siblingComponent: true,
    },
];
export var DocumentStatusEnum;
(function (DocumentStatusEnum) {
    DocumentStatusEnum[DocumentStatusEnum["AgendaActive"] = 1] = "AgendaActive";
    DocumentStatusEnum[DocumentStatusEnum["AgendaInactive"] = 2] = "AgendaInactive";
    DocumentStatusEnum[DocumentStatusEnum["MinutesActive"] = 4] = "MinutesActive";
    DocumentStatusEnum[DocumentStatusEnum["MinutesInactive"] = 5] = "MinutesInactive";
    DocumentStatusEnum[DocumentStatusEnum["CommitteeDocumentActive"] = 7] = "CommitteeDocumentActive";
    DocumentStatusEnum[DocumentStatusEnum["CommitteeDocumentInactive"] = 8] = "CommitteeDocumentInactive";
    DocumentStatusEnum[DocumentStatusEnum["BallotQueueActive"] = 13] = "BallotQueueActive";
    DocumentStatusEnum[DocumentStatusEnum["BallotQueueInactive"] = 14] = "BallotQueueInactive";
    DocumentStatusEnum[DocumentStatusEnum["BallotItemActive"] = 15] = "BallotItemActive";
    DocumentStatusEnum[DocumentStatusEnum["BallotItemInactive"] = 16] = "BallotItemInactive";
    DocumentStatusEnum[DocumentStatusEnum["VoteAttachmentActive"] = 9] = "VoteAttachmentActive";
    DocumentStatusEnum[DocumentStatusEnum["VoteAttachmentInactive"] = 10] = "VoteAttachmentInactive";
})(DocumentStatusEnum || (DocumentStatusEnum = {}));
export var COLLABORATIONAREADRAFTHEADERINFO = [
    {
        title: "Draft Name",
        hasSorting: true,
        className: "",
        sortKey: "BusinessRecordName",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Collaboration ID",
        hasSorting: true,
        className: "",
        sortKey: "CollaborationId",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Committee",
        hasSorting: true,
        className: "",
        sortKey: "Committee",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Work Item",
        hasSorting: true,
        className: "",
        sortKey: "WorkItem",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Modified Date",
        hasSorting: true,
        className: "",
        sortKey: "ModifiedDate",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
];
export var webVoteAttachment = "4";
